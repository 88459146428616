import React from "react"

import SEO from "../components/seo"
import { Container, Row, Col, Button } from "react-bootstrap"

import "../sass/index.scss"

const NotFoundPage = () => (
  <Container fluid className="vh-100 d-flex justify-content-center align-items-center">
    <SEO />
    <Row className="text-center">
      <Col>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <Button variant="primary" size="xl" href="/">
          Go Back
        </Button>
      </Col>
    </Row>
  </Container>
)

export default NotFoundPage
